import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
      <div className='footer-version'>v0.0.0</div>
      <div className='footer-usefool-links'>
        <ul>
          <li>
            <Link to='/about'>About</Link>
          </li>
          <li>
            <Link to='/help'>Help</Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
