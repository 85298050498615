import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { postSkroutzInvoice, updateOrderMark } from '../features/skroutz/skroutzSlice'
import { Link, useNavigate } from 'react-router-dom'
import SmallSpinner from './SmallSpinner'
import { BsFillCheckCircleFill } from 'react-icons/bs'

function SkroutzOrderInvoice({ order, printedInvoice, invoice_mark }) {
  const dispatch = useDispatch()

  const [mark, setMark] = useState('')
  console.log(mark)

  const { user } = useSelector((state) => state.auth)

  const { isLoadingAade } = useSelector((state) => state.aade)
  const { postInvoiceLoading } = useSelector((state) => state.skroutz)

  // Create and post invoice to AADE
  const submitInvoice = async (e) => {
    toast.error('Δεν είναι δυνατή η έκδοση παραστατικού')
    return
    const userId = user._id
    const data = { ...order, userId }
    // dispatch(postSkroutzInvoice(data))
    //   .unwrap()
    //   .then((response) => {
    //     toast.success('Το παραστατικο στάλθηκε στον σκρουτζ')
    //   })
    //   .catch((error) => toast.error(error))
  }

  const saveMark = (e) => {
    e.preventDefault()

    if (!mark) return toast.error('Παρακαλώ εισάγετε ΜΑΡΚ')

    dispatch(updateOrderMark({ id: order.code, mark }))
      .unwrap()
      .then((response) => {
        toast.success('Το ΜΑΡΚ αποθηκεύτηκε')
      })
      .catch((error) => console.log(error))
  }

  return (
    <div>
      <div>
        {printedInvoice ? (
          <>
            <div className='flex-center-5'>
              <BsFillCheckCircleFill fill='green' /> Το παραστατικό έχει εκδωθεί{' '}
            </div>
            <div className='mg-top-15'>
              Μαρκ: <Link to={`/invoices/${invoice_mark}`}>{invoice_mark}</Link>
            </div>
            <div className='skroutz-order-voucher mg-top-15' onClick={submitInvoice}>
              Δημιουργία νέου παραστατικού
            </div>
          </>
        ) : (
          <div>
            <div>Δεν έχει εκδωθεί παραστατικό</div>
            {order?.state !== 'cancelled' && (
              <button className='skroutz-order-voucher mg-top-15' onClick={submitInvoice} disabled={isLoadingAade}>
                {postInvoiceLoading ? (
                  <span className='small-spiner-flex-center'>
                    <SmallSpinner />
                  </span>
                ) : (
                  'Έκδοση παραστατικού'
                )}
              </button>
            )}
          </div>
        )}
      </div>
      <div className='mark-input'>
        Εισαγωγή ΜΑΡΚ:
        <input type='text' placeholder='ΜΑΡΚ' onChange={(e) => setMark(e.target.value)} />
        <button className='btn-submit' onClick={saveMark}>
          Αποθήκευση
        </button>
      </div>
    </div>
  )
}

export default SkroutzOrderInvoice
